import type {
  Protocol,
  ProtocolDTO,
} from '~/types/protocol'

export const useProtocol = defineStore('protocol', () => {
  const baseApiURL = useRuntimeConfig().public.apiUrl
  const isPrintMode = ref<boolean>(false)
  const isUploadedImg = ref<boolean>(false)

  const protocols: Ref<Protocol[]> = useState(
    'protocols:active',
    () => [],
  )
  const loading: Ref<boolean> = useState(
    'protocol:loading',
    () => false,
  )
  const protocol: Ref<Protocol | null> = useState(
    'protocol:active',
    () => null,
  )

  async function createProtocol(protocolData: ProtocolDTO) {
    try {
      const newFolder = await $fetch<any>(
        `${baseApiURL}/api/protocols/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
          body: {
            ...protocolData,
          },
        },
      )

      protocols.value.push(newFolder)

      return newFolder
    } catch (e: any) {
      console.error(e)
      throw e
    }
  }

  async function saveProtocol(protocolData: ProtocolDTO) {
    try {
      return $fetch<any>(
        `${baseApiURL}/api/protocols/save`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
          body: {
            ...protocolData,
          },
        },
      )
    } catch (e: any) {
      console.error(e)
      throw e
    }
  }

  async function getProtocolsByCompanyId(
    company_id: number,
  ) {
    try {
      protocols.value = await $fetch<Protocol[]>(
        `${baseApiURL}/api/protocols/${company_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )

      return protocols
    } catch (e: any) {
      console.error(e)
      throw e
    }
  }

  async function getProtocolByFolderId(folder_id: number) {
    loading.value = true

    try {
      protocol.value = await $fetch<Protocol>(
        `${baseApiURL}/api/protocols/folder/${folder_id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )
      return protocol
    } catch (e: any) {
      console.error(e)
      throw e
    } finally {
      loading.value = false
    }
  }

  async function deleteProtocol(id: number) {
    try {
      await $fetch<any>(
        `${baseApiURL}/api/protocols/${id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${useCookie('auteba').value}`,
          },
        },
      )

      const index = protocols.value.findIndex(
        (protocol) => protocol.id === id,
      )

      if (index !== -1) {
        protocols.value.splice(index, 1)
      }

      return protocols
    } catch (e: any) {
      console.error(e)
      throw e
    }
  }

  const changePrintMode = () => {
    isPrintMode.value = !isPrintMode.value
  }

  return {
    loading,
    protocols,
    protocol,
    isPrintMode,
    isUploadedImg,
    changePrintMode,
    saveProtocol,
    createProtocol,

    getProtocolsByCompanyId,
    getProtocolByFolderId,

    deleteProtocol,
  }
})
